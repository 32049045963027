<template>
    <div>
        <div>
            <b-card no-body>
                <b-tabs card v-model="activeTab">
                    <div class="col-md-10 w-100 mx-auto">
                        <!-- Tab 1: Generate 'PMR' campaigns -->
                        <b-tab title="Generate 'PMR' campaigns">
                            <b-row class="mb-4">
                                <b-col cols="auto" class="text-center">
                                    <b-button variant="outline-primary" class="p-1" size="sm"
                                        @click="downloadPmrCsvTemplate" title="Télécharger Template V1">
                                        Template V1
                                    </b-button>
                                </b-col>
                                <b-col cols="auto" class="text-center">
                                    <b-button variant="outline-primary" class="p-1" size="sm"
                                        @click="downloadPmrCsvTemplateV2" title="Télécharger Template V2">
                                        Template V2
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group label="Select Version">
                                        <b-form-checkbox class="pr-1" id="checkbox-7" v-model="version"
                                            name="checkbox-4" value="v1">
                                            Version 1
                                        </b-form-checkbox>
                                        <b-form-checkbox class="pr-1" id="checkbox-8" v-model="version"
                                            name="checkbox-5" value="v2">
                                            Version 2
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-form @submit.stop.prevent>
                                <b-row class="align-items-center">
                                    <b-col cols="6">
                                        <b-form-group label="Upload CSV File" label-for="input-file">
                                            <b-form-file v-model="fileData" :state="Boolean(fileData)"
                                                placeholder="Choose a file..."
                                                drop-placeholder="Drop file here..."></b-form-file>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="auto" class="text-left">
                                        <b-btn variant="success" class="p-1" @click="generateCampaigns"
                                            :disabled="loading" size="sm">
                                            <span v-if="loading">Loading...</span>
                                            <span v-else>Create Campaigns</span>
                                        </b-btn>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <div v-if="loading" class="overlay">
                                <div class="spinner"></div>
                            </div>

                            <b-row v-if="responseDetails">
                                <b-col cols="12">
                                    <b-card class="mt-3" title="Campaign Creation Details">
                                        <p v-if="responseDetails.missingPointCodes.length > 0">
                                            <strong>Point codes Inexistant(campagne non Créer):</strong>
                                        <ul>
                                            <li v-for="(point, index) in responseDetails.missingPointCodes"
                                                :key="index">
                                                {{ point }}
                                            </li>
                                        </ul>
                                        </p>
                                        <p v-if="responseDetails.missingUids.length > 0">
                                            <strong>UIDs Inexistant:</strong>
                                        <ul>
                                            <li v-for="(uid, index) in responseDetails.missingUids" :key="index">
                                                {{ uid }}
                                            </li>
                                        </ul>
                                        </p>

                                        <p v-if="responseDetails.missingCreaData.length > 0">
                                            <strong>Crea Inexistant:</strong>
                                        <ul>
                                            <li v-for="(crea, index) in responseDetails.missingCreaData" :key="index">
                                                {{ crea }}
                                            </li>
                                        </ul>
                                        </p>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!-- Tab 2: Search for campaigns -->
                        <b-tab title="Search for campaigns" ref="myTable" @row-clicked="toggleRowSelection">
                            <b-row class="pb-4">
                                <b-form-checkbox class="pr-1" id="checkbox-4" v-model="quotationFilter"
                                    name="checkbox-4" value="accepted" unchecked-value="not_accepted"
                                    @change="toggleFilters('quotation_label')">
                                    quotation_label</b-form-checkbox>
                                <b-form-checkbox class="pr-1" id="checkbox-5" v-model="flagFilter" name="checkbox-5"
                                    value="accepted" unchecked-value="not_accepted" @change="toggleFilters('flag')">
                                    flag</b-form-checkbox>
                            </b-row>

                            <b-row>
                                <b-col cols="6">
                                    <b-form-group v-if="quotationFilter === 'accepted'" label="quotation_label"
                                        label-for="input-quotation">
                                        <b-form-input v-model="quotation_label" required></b-form-input>
                                    </b-form-group>

                                    <b-form-group v-if="flagFilter === 'accepted'" label="Flag"
                                        label-for="input-quotation">
                                        <b-form-input v-model="flag" required></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-button v-if="quotationFilter" variant="success" class="mt-2"
                                        @click="filterCampaigns()" :disabled="loading">
                                        <feather-icon icon="SearchIcon"></feather-icon>
                                    </b-button>

                                    <b-button v-if="flagFilter" variant="success" class="mt-2"
                                        @click="filterCampaigns()" :disabled="loading">
                                        <feather-icon icon="SearchIcon"></feather-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-col>
                                <div>
                                    <b-col cols="6">
                                        <h4>You have selected ({{ selected.length }}) campaigns</h4>
                                    </b-col>

                                    <b-col cols="6">
                                        <b-form-checkbox id="checkbox-1" v-model="selectAll" @change="toggleSelectAll">
                                            Tout sélectionner / déselectionner
                                        </b-form-checkbox>
                                    </b-col>

                                    <b-table class="custom-selected pt-2" :fields="computedFields" responsive striped
                                        hover :items="filteredData" selectable @row-selected="onRowSelected"
                                        ref="selectableTable">
                                        <template #cell(details)="row">
                                            <b-button @click="openJsonDataModal(row)" v-b-modal="'modal-' + row.index"
                                                variant="info"> View </b-button>
                                            <b-modal ok-only :id="'modal-' + row.index" :title="row.item.campaign_code"
                                                size="lg" :centered="true" :modal-class="'center-style'">
                                                <VueJsonViewer :value="row.item.details" :copyable="true"
                                                    :preview-mode="true" :expanded="true" v-if="row.item.details">
                                                </VueJsonViewer>
                                            </b-modal>
                                        </template>
                                    </b-table>
                                </div>
                            </b-col>
                        </b-tab>

                        <!-- Tab 3: Create Campaign for TTD -->
                        <b-tab title="Create Campaign for TTD">
                            <b-col cols="6">
                                <h3>You have selected ({{ selected.length }}) campaigns</h3>
                            </b-col>
                            <b-table :fields="computedFields" :items="selected" responsive striped>
                                <template selected-variant="secondary" #cell(details)="row">
                                    <b-button @click="openJsonDataModalTwo(row)" v-b-modal="'modal_' + row.index"
                                        variant="info">View</b-button>
                                    <b-modal ok-only :id="'modal_' + row.index" :title="row.item.campaign_code"
                                        size="lg" :centered="true" :modal-class="'center-style'">
                                        <VueJsonViewer :value="row.item.details" :copyable="true" :preview-mode="true"
                                            :expanded="true" v-if="row.item.details"></VueJsonViewer>
                                    </b-modal>
                                </template>
                            </b-table>
                            <b-row class="pb-4">
                                <b-col>
                                    <b-btn variant="success" class="d-flex ml-auto" @click="createForTtd()">
                                        <span v-if="loading">Chargement...</span>
                                        <span v-else>Create for TTD</span>
                                    </b-btn>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!-- Tab 4: Update / Add finalData -->
                        <b-tab title="Update / Add finalData">
                            <b-row>
                                <b-col cols="6">
                                    <b-button variant="outline-primary" class="m-6 mb-4 p-2" size="lg"
                                        @click="downloadPmrCommentTemplate" title="Download template">
                                        <feather-icon icon="DownloadIcon"></feather-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-col cols="6">
                                <b-form-group label="File in CSV format" label-for="input-file">
                                    <b-form-file v-model="finalFileData" :state="Boolean(finalFileData)"
                                        placeholder="Choose a file..."
                                        drop-placeholder="Drop file here..."></b-form-file>
                                </b-form-group>
                                <b-form-group>
                                    <b-btn variant="success" class="d-flex ml-auto" @click="addFinalData()"
                                        :disabled="!finalFileData">
                                        <span v-if="loading">Chargement...</span>
                                        <span v-else>Add finalData</span>
                                    </b-btn>
                                </b-form-group>
                            </b-col>
                        </b-tab>
                    </div>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VueJsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';




var FormData = require('form-data');
var fs = require('fs');

export default {
    data() {
        return {
            loading: false,
            selectedBrand: '',
            quotation_label: null,
            flag: null,
            selectAll: '',
            creaCode: '',
            selectedProductList: '',
            status: '',
            creaCode: '',
            activeTab: 0,
            product: '',
            QuotationLabel: '',
            fileData: null,
            responseDetails: null,
            finalFileData: null,
            formValid: false,
            todayDate: new Date(),
            quotationFilter: false,
            flagFilter: false,
            filterLabel: null,
            filterMedia: null,
            filterLabelData: null,
            filteredData: [],
            jsonDataModal: false,
            selectedData: null,
            selected: [],
            currentPage: 1,
            itemsPerPage: 20,
            selectedItems: [],
            apiversion: '',
            version: ''
        };
    },
    components: {
        VueJsonViewer,
    },

    watch: {
        selectedProductList: 'populateInputFields',
    },

    computed: {
        paginatedItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredData.slice(start, end);
        },
        computedFields() {
            if (this.apiversion === 'v1') {
                return [
                    'campaign_code',
                    'start_date',
                    'end_date',
                    'point_code',
                    'origin',
                    'brand',
                    'is_parent',
                    'media_support_code',
                    'parent',
                    'details',
                    'nb metrics',
                ]
            } else {
                return ['timestamp', 'campaign_code', 'trader_id', 'point_code', 'origin', 'brand', 'is_parent', 'media_support_code', 'start_date', 'parent', 'details']
            }
        },


    },
    methods: {
        resetForm() {
            this.creaCode = '';
            this.selectedProductList = '';
            this.QuotationLabel = '';
            this.fileData = null;
            this.selectedBrand = '';
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows();
        },

        toggleFilters(selectedFilter) {
            if (selectedFilter === 'quotation_label') {
                this.flagFilter = false;
            } else if (selectedFilter === 'flag') {
                this.quotationFilter = false;
            }
        },


        toggleRowSelection(item, index) {
            this.$refs.myTable.toggleDetails(index);
        },

        onRowSelected(items) {
            this.selected = items;

            this.selectedItems.concat(items);
        },
        toggleSelectAll() {
            if (this.selectAll) {
                this.selectAllRows();
            } else {
                this.clearSelected();
            }
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows();
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
        },

        async filterCampaigns() {
            let submissiondata = {
                is_parent: 1,
                status: [0],
                quotation_label: this.quotation_label,
                flag: this.flag
            };

            try {
                const filterCampaigns = await this.$store.dispatch('app/getFilteredCampaigns', submissiondata)
                if (filterCampaigns) {
                    this.filteredData = filterCampaigns.data.result;
                    this.filterLabelData = null;
                    this.quotation_label = null;
                    this.flag = null;
                }
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `no campaigns found (${error.message})`,
                        icon: 'exclamation-triangle',
                        variant: 'danger',
                    },
                });
            }
        },

        async createForTtd() {
            try {
                const createCampForTtd = await this.$store.dispatch('app/createCampaignForTtd', this.selected)

                if (createCampForTtd) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Campaign creation for Ttd has started",
                            icon: 'smile',
                            variant: 'success',
                        },
                    });
                }

            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: error.message,
                        icon: 'exclamation-triangle',
                        variant: 'danger',
                    },
                });
            }
        },

        closeJsonDataModal() {
            this.selectedData = null;
            this.jsonDataModal = false;
        },
        openJsonDataModal(row) {
            this.selectedData = row;
            this.jsonDataModal = true;
        },

        openJsonDataModalTwo(row) {
            this.selectedData = row;
            this.jsonDataModal = true;
        },

        generateCampaigns() {
            try {
                if (this.fileData) {
                    var form = new FormData();
                    form.append('file', this.fileData, this.fileData.name);
                    this.$store.dispatch('app/createPmrCampaigns', { form, version: this.version }).then((res) => {
                        if (res.status === 200) {
                            this.fileData = ''
                            this.responseDetails = res.data;
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'your campaigns have successfully been created',
                                    icon: 'smile',
                                    variant: 'success',
                                },
                            });
                        }
                    });
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Le fichier csv est requis",
                            icon: 'exclamation-triangle',
                            variant: 'danger',
                        },
                    });
                }
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "une erreur est survenu pendant la création des campagnes",
                        icon: 'exclamation-triangle',
                        variant: 'danger',
                    },
                });

            }
        },
        async downloadPmrCsvTemplate() {
            try {
                this.$root.toggleGlobalLoading(true);

                const data = {
                    "point_code": "20101",
                    "point_name": "SIVAM MONTLUEL",
                    "quotationLabel": "ssssss",
                    "brand": "citroen",
                    "uid": "0",
                    "creaCode": "CREA1",
                    "url": "https://www.toyota.fr/offres/aygo-x?dealerid=40101&campaignID",
                    "email": "kcerdan@adcleek.com",
                    "flag": "KCE-test",
                    "media_category1": "DSK",
                    "quantity1": "25000",
                    "price1": "250",
                    "startDate1": "2024-02-01",
                    "endDate1": "2024-02-14",
                    "ttdCreativeId1": "np207mmt|up207yyt|up2056yt",
                    "media_category2": "MOB",
                    "quantity2": "100000",
                    "price2": "1000",
                    "startDate2": "2024-02-10",
                    "endDate2": "2024-02-24",
                    "ttdCreativeId2": "up207yyt"
                }


                const headers = Object.keys(data).join(',');
                const values = Object.values(data).map(value => `"${value}"`).join(',');
                const csv = `${headers}\n${values}`;

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'templatefile_pmr.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                this.$root.toggleGlobalLoading(false);
            } catch (error) {
                console.log(error);
                this.$root.toggleGlobalLoading(false);
            }
        },
        async downloadPmrCsvTemplateV2() {
            try {
                this.$root.toggleGlobalLoading(true);

                const data = {
                    "point_code": "020012C",
                    "quotationLabel": "PMR Juillet 2024",
                    "brand": "citroen",
                    "creaCode": "4ULWJ",
                    "url": "https://www.citroen.fr/offres/particuliers/electrifiedays",
                    "email": "flabourot@adcleek.com",
                    "zoneCode": "ZV4NN24",
                    "flag": "PMR FLA",
                    "media_category1": "DSK",
                    "quantity1": 0,
                    "price1": 3000,
                    "startDate1": "2024-12-01",
                    "endDate1": "2024-12-20",
                    "media_category2": "MOB",
                    "quantity2": 0,
                    "price2": 3000,
                    "startDate2": "2024-12-01",
                    "endDate2": "2024-12-20"
                }
                const headers = Object.keys(data).join(',');
                const values = Object.values(data).map(value => `"${value}"`).join(',');
                const csv = `${headers}\n${values}`;

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'templatefile_pmr.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                this.$root.toggleGlobalLoading(false);
            } catch (error) {
                console.log(error);
                this.$root.toggleGlobalLoading(false);
            }
        },
        async downloadPmrCommentTemplate() {
            try {
                this.$root.toggleGlobalLoading(true);

                const data = {
                    "campaign_code": "LMP_CITROEN_ece2ee944c3c3383dbd9b6e900107668_1724850940213",
                    "media_category1": "DSK",
                    "quantity1": 300,
                    "amount1": 300,
                    "comment1": "test-comment"
                }
                const headers = Object.keys(data).join('|');
                const values = Object.values(data).map(value => `${value}`).join('|');
                const csv = `${headers}\n${values}`;

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'templatefile_pmr_comment.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                this.$root.toggleGlobalLoading(false);
            } catch (error) {
                console.log(error);
                this.$root.toggleGlobalLoading(false);
            }
        },
        async addFinalData() {
            try {
                var form = new FormData();

                if (this.finalFileData) {
                    form.append('file', this.finalFileData, this.finalFileData.name);
                }

                const addFinalData = await this.$store.dispatch('app/generateFinalData', form)

                if (addFinalData.data === 'updated') {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'FinalData successfully added / updated',
                            icon: 'smile',
                            variant: 'success',
                        },
                    });
                    this.finalFileData = ""
                }
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "erreur d'ajout des finalData",
                        icon: 'exclamation-triangle',
                        variant: 'danger',
                    },
                });
            }

        }
    },
};
</script>

<style>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.table.b-table>tbody .b-table-row-selected.table-active td {
    background-color: lightskyblue !important;
}

.table.b-table.table-hover>tbody>tr.table-active td {
    color: black !important;
}

.table.b-table.table-hover>tbody>tr.table-active:hover td {
    color: black !important;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: peachpuff;
}
</style>
